html, body {
  font-size: 16px;
}

body {
  background: url('/img/bg.gif');
  font-family: "proxima-nova", sans-serif;
}

a {
  &:focus {
    outline: none;
  }
}

.alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
}

.text-danger {
  color: $red !important;
}

.dashboard-wrap {
  position: relative;
  background: $white;
  margin-top: 175px;
  min-height: 1038px;
  border-radius: 7px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;

  @media only screen and (max-width : 1200px) {
    margin-top: 35px;
  }

  &.admin-wrap {

    @media only screen and (max-width: 1200px) {
      margin-top: 35px;
    }
  }
}


aside.sidebar {
  background: $offwhite;
  width: 225px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-right: 1px solid $border-color;
  visibility: visible;

  @media only screen and (max-width : 1200px) {
    display: none !important;
  }

  &.admin-menu {
    @media only screen and (max-width : 1200px) {
      display: none !important;
    }
  }

  .logo-area {
    background: $white;
    height: 140px;
    border-top-left-radius: 7px;

    img.logo {
      margin-top: -90px;
      margin-left: -25px;
    }
  }

  span.navigation-header {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: $blue;
    display: block;
    margin-top: 40px;
    margin-left: 24px;
    margin-bottom: 20px;
  }

  ul.site-navigation {
    padding-left: 0;

    li.nav-item {
      list-style: none;
      display: block;
      height: 62px;
      padding-left: 24px;
      transition: background 0.4s;

      span.icon-wrap {
        padding-top: 19px;
        width: 25px;
        float: left;
        display: inline-block;
        margin-right: 10px;

        img.menu-icon {
          display: block;
          margin: 0 auto;
        }



      }

      a {
        text-transform: uppercase;
        color: $gray-text;
        font-size: 13px;
        line-height: 62px;
        font-weight: 500;
        width: 100%;
        display: block;
        transition: color 0.4s;

        &:hover {
          text-decoration: none;
        }
      }

      &.active, &:hover {
        background: $blue;

        a, i {
          color: $white;
        }

        img {
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
        }
      }
    }
  }
}

.content-area {
  margin-left: 225px;

  @media only screen and (max-width : 1200px) {
    margin-left: 0;
    padding-top: 30px;
  }

  &.admin-content {
    @media only screen and (max-width : 1200px) {
      margin-left: 0;
    }

    .user-bar {
      @media only screen and (max-width : 1200px) {
        display: none !important;
      }
    }
  }

  .user-bar {
    border-bottom: 1px solid $border-color;
    height: 55px;
    padding-left: 25px;
    padding-right: 0;

    @media only screen and (max-width : 1200px) {
      display: none;
    }

    span.call-us {
      color: $gray-text;
      line-height: 55px;
      font-size: 18px;
      font-weight: 500;

      a {
        color: $gray-text;

        &:hover {
          text-decoration: none;
        }
      }

      @media only screen and (max-width : 1255px) {
        display: none !important;
      }
    }


    span.site-status {
      line-height: 55px;

      .label {
        margin-left: 10px;
      }

      @media only screen and (max-width : 1200px) {
        display: none;
      }
    }

    ul {
      padding-left: 0;

      li {
        list-style: none;

        a {
          line-height: 55px;

          &.user-name {
            font-size: 16px;
            font-weight: 200;
            color: $gray-text;
            margin-right: 10px;
          }
        }
      }

      &.user-nav {
        float: right;
      }
    }

    span.caret-wrap {
      width: 55px;
      height: 55px;
      background: $dark-green;
      display: inline-block;
      text-align: center;
      border-top-right-radius: 7px;

      @media only screen and (max-width : 1255px) {
        display: none !important;
      }

      .caret {
        color: $white;
        border-top: 5px dashed;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
      }
    }
  }

  .page-content {
    padding: 15px 15px 40px;
    min-height: 755px;
    overflow: hidden;


    h1 {
      color: $dark-green;
      font-weight: normal;
      font-size: 26px;
      margin-bottom: 40px;

      @media only screen and (max-width : 1200px) {
        margin-top: 65px;
      }
    }

    h2 {
      color: $gray-text;
      font-weight: normal;
      font-size: 22px;
      margin-bottom: 15px;
      margin-top: 35px;
    }

    p {
      font-weight: 300;
      color: $gray-text;
      line-height: 30px;
    }

    hr {
      margin-top: 42px;
      margin-bottom: 42px;
    }

    ul {
      padding-left: 15px;

      li {
        color: $gray-text;
      }

      &.domain-names {
        padding-left: 0;
        display: inline-block;

        li {
          list-style: none;
          margin-bottom: 20px;
        }
      }

      &.login-list {
        padding-left: 0;

        li {
          list-style: none;
          margin-bottom: 20px;

          b {
            color: $dark-green;
          }
        }
      }
    }

    table.rowclick {
      tr {
        &:hover {
          cursor: pointer;
        }
      }
    }

    span.ftp-domain {
      color: $blue;
      font-weight: 500;
    }

    &.extra-content {
      padding: 15px 15px 40px;

      span.open-menu {
        @media only screen and (max-width : 1200px) {
          display: block !important;
        }
      }
    }

    form.user-search {
      margin-bottom: 35px;

      @media only screen and (max-width : 1200px) {
        margin-top: 65px;
      }
    }

    span.total {
      float: right;
      margin-top: 40px;
    }

    p.seo-list-header {
      margin-bottom: 20px;
      color: $dark-green;
      font-weight: 500;
    }

    ul.seo-list {
      li {
        color: $dark-green;
        font-weight: 500;
      }
    }

    ul.improve-web-traffic {
      li {
        margin-bottom: 10px;
      }
    }

    .account-manager {
      margin-bottom: 60px;

      p {
        margin-bottom: 5px !important;
        color: $dark-green !important;

        span.phone-number {
          font-weight: bold;
        }
      }

      p.name {
        font-weight: bold;

        span.position {
          font-weight: normal;
        }
      }

      a {
        color: $blue;
        font-weight: bold;
      }
    }
  }
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  background: $offwhite;
  overflow-x: hidden;
  padding-top: 60px;
  transition: 0.5s;
  -webkit-box-shadow: 3px 0px 5px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 3px 0px 5px 0px rgba(0,0,0,0.15);
  box-shadow: 3px 0px 5px 0px rgba(0,0,0,0.15);

  span.user {
    text-align: center;
    display: block;
    font-style: italic;
  }

  span.navigation-header {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: $blue;
    display: block;
    margin-top: 60px;
    margin-left: 24px;
    margin-bottom: 20px;
  }

  ul.site-navigation {
    padding-left: 0;

    li.nav-item {
      list-style: none;
      display: block;
      height: 62px;
      padding-left: 24px;
      transition: background 0.4s;

      &.center {
        text-align: center;
      }

      a {
        text-transform: uppercase;
        color: $gray-text;
        font-size: 13px;
        line-height: 62px;
        font-weight: 500;
        width: 100%;
        display: block;
        transition: color 0.4s;

        &:hover {
          text-decoration: none;
        }
      }

      &.active, &:hover {
        background: $blue;

        a, i {
          color: $white;
        }
      }
    }
  }
}


.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 40px;
}

.smaller-logo-area {
  a {
    display: block;
    width: 229px;
    margin: 0 auto;

    img {

    }
  }

  &.admin-logo {
    @media only screen and (max-width : 1200px) {
      display: block !important;
    }
  }
}

.open-menu {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;

  i {
    font-size: 30px;
    color: $blue;
  }
}

.userlogout {
  text-align: center;

  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.login-wrapper {
  max-width: 875px;
  min-height: 553px;
  background: white;
  border-radius: 7px;
  margin: 200px auto 0;

  @media only screen and (max-width : 768px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  .login-wrapper-inner {
    position: relative;
    padding-top: 80px;


    h1 {
      font-size: 20px;
      color: $gray-text;
      text-align: center;
      text-transform: uppercase;
    }

    img.logo {
      position: absolute;
      top: -73px;
      left: -57px;

      @media only screen and (max-width : 870px) {
        top: -173px;
        left: 50%;
        margin-left: -114.5px;
      }
    }

    img.dixie {
      position: absolute;
      right: 30px;
      bottom: 0;

      @media only screen and (max-width : 993px) {
        display: none;
      }
    }

    .form-wrapper {
      max-width: 515px;
      margin: 45px auto 0;
      padding-bottom: 40px;

      label {
        text-transform: uppercase;
        color: $gray-text;
        font-size: 13px;
      }

      a.forgot-password {
        float: right;
        color: $red;
      }

      input[type=text], input[type=password], input[type=email] {
        height: 70px;
        font-size: 40px;
        margin-bottom: 25px;
      }

      button {
        height: 70px;
        width: 243px;
        margin: 0 auto;
        border-radius: 0;
        display: block;
        font-size: 16px;
        text-transform: uppercase;
        transition: all 0.5s;
      }

      .checkbox {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 25px;
      }

      span.call-us {
        display: block;
        text-align: center;
        margin-top: 40px;
        font-size: 18px;
        color: $gray-text;
      }

      &.password-reset {
        button {
          //width: 275px;
        }
      }
    }
  }
}

.assignment-info, .invoices {
  h1, h2 {
    padding-left: 15px;
  }

  h1 {
    font-size: 20px !important;
  }

  i {
    margin-right: 10px;
  }

  table {
    margin: 0 15px;
  }
}

p.copy {
  font-size: 14px;
  color: $gray-text;
  margin-top: 25px;
  margin-bottom: 35px;

  span {
    color: $red;
    z-index: 9999;

    a {
      color: $red;
    }
  }
}

.reset-password-wrapper {
  margin-top: 40px;

  .logo-area {
    display: block;
    margin: 0 auto;
    margin-bottom: 45px;
    width: 229px;
  }

  .panel-heading {
    color: $dark-green;
  }
}

.change-password-wrapper {
  padding-top: 100px;

  img {
    display: block;
    margin: 0 auto 40px;
    max-width: 100%;
  }
}

canvas#login_wrapper {
  position: absolute;
}

ul.analytics-toc {
  padding-left: 0 !important;
  max-width: 100%;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;

  li {
    display: inline-block;
    margin-left: 20px;

    a {
      cursor: pointer;
    }

    a[aria-expanded="true"] {
      color: $red;
      font-weight: bold;
    }
  }
}

.analytics {

  img.icon-analytics {
    display: inline-block;
    margin-right: 25px;
  }
  .panel-heading {
    background-color: $red;
    color: $white
  }

  .panel-body {
    background-color: $offwhite;
    padding-top: 30px;

    p {
      color: $blue;
      font-size: 18px;
      margin-bottom: 20px;
    }

    @media only screen and (max-width : 1440px) {
      text-align: center;
    }
  }
}

.fa-heart {
  color: $red;
  font-size: 18px;
  margin-right: 10px;
  vertical-align: middle;
}

.leave-review {

  padding-left: 5px;

  .navigation-header {
    margin-bottom: 10px;
  }

  ul {
    display: inline-block;
    padding-left: 24px;
    margin-top: -10px;

    li.review-item {
      list-style: none;
      display: inline-block;
      margin-left: 38px;

      a {
        i {
          color: $red;
          font-size: 30px;
        }
      }

      &:hover {
        a {

        }
      }

      &.facebook {
        a {
          i {
            color: $blue;
          }
        }
      }
    }
  }
}

.home-service {
  text-align: center;
  padding-top: 40px;

  a.service {
    text-transform: uppercase;
    display: block;
    max-width: 115px;
    margin: 20px auto 0;
    font-size: 14px;
    color: $dark-green;

    &:hover, &:focus {
      text-decoration: none;
    }
  }

  img {
    &:hover {

    }
  }

  @media only screen and (max-width : 1400px) {
    width: 50% !important;
  }

  @media only screen and (max-width : 480px) {
    width: 100% !important;
  }
}

.login-information {

  span.website-password-hidden, span.ftp-password-hidden {
    letter-spacing: 3px;
  }

  span.website-password-shown, span.ftp-password-shown {
    display: none;
  }

  a.btn {
    margin-left: 25px;
    background-color: $brand-success;
    color: $white;
    border: none !important;
    width: 125px;

    &:active, &:focus {
      background-color: $brand-success;
    }

    &.ishidden {
      background-color: $brand-warning;

      &:active, &:focus {
        background-color: $brand-warning;
      }
    }
  }
}

.latest-invoices {
  margin-top: 60px;
}

#create_task_form {
  .help-inline {
    display: none;
  }

  .alert {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
  }
}

.create-task-form-wrapper, .update-notifications-form-wrapper {

   position: relative;

   .spinner {
     width: 40px;
     height: 40px;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     opacity: 0;
   }

   .double-bounce1, .double-bounce2 {
     width: 100%;
     height: 100%;
     border-radius: 50%;
     background-color: $red;
     opacity: 0.6;
     position: absolute;
     top: 0;
     left: 0;

     -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
     animation: sk-bounce 2.0s infinite ease-in-out;
   }

   .double-bounce2 {
     -webkit-animation-delay: -1.0s;
     animation-delay: -1.0s;
   }

   @-webkit-keyframes sk-bounce {
     0%, 100% { -webkit-transform: scale(0.0) }
     50% { -webkit-transform: scale(1.0) }
   }

   @keyframes sk-bounce {
     0%, 100% {
       transform: scale(0.0);
       -webkit-transform: scale(0.0);
     } 50% {
         transform: scale(1.0);
         -webkit-transform: scale(1.0);
       }
   }
 }

.upload-invoice-form-wrapper {

  position: relative;

  .spinner {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  .double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $red;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
  }

  .double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  @-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
  }

  @keyframes sk-bounce {
    0%, 100% {
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
      }
  }
}

.update-notifications-form-wrapper {
  input[type="checkbox"] {
    margin-right: 10px;
  }

  .button-row {
    display: block;
    width: 100%;
    margin-top: 40px;

    .btn {
      margin-right: 20px !important;
    }
  }
}

.fa-trash-o {
  color: $red;
  transition: hover 0.5ms;
  cursor: pointer;

  &:hover {
    color: lighten($red, 10%);
  }
}

.modal-dialog {

  .img-preview {
    position: relative;

    img {
      display: block;
      max-width: 300px;
      margin: 0 auto;
      border: 10px solid $blue;
    }

    .preview {
      z-index: 999;
      position: absolute;
      width: 100%;
      text-align: center;
      left: 0;
      top: 50%;
      margin-top: -16px;

      span {
        text-transform: uppercase;
        font-size: 32px;
        color: #ccc;
        font-weight: bold;
      }
    }
  }

  @media only screen and (min-width : 768px) {
    width: 350px !important;
  }
}

a.show-details {
  &:after {
    content: "\f054" !important;
    font-family: FontAwesome;
    margin-left: 10px;
    font-size: 10px;
  }
}

a.showing-details {
  &:after {
    content: "\f078" !important;
    font-family: FontAwesome;
  }
}

.circle-tile-footer-wrapper {
  position: relative;

  i {
    position: absolute;
    top: 9px;
    margin-left: 8px;
  }
}

span.invoice-status {
  cursor: pointer;
}

